export default {
  goodsList: [
    {
      id: '111',
      categoryId: '378957',
      categoryName: '半导体',
      subCategoryId: '11',
      subCategoryName: '二极管',
      cover:'https://img2.baidu.com/it/u=850422026,4231251906&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
      name: 'BAV70',
      address: '广东省 深圳市',
      bank: 7200,
      brand: '南芯',
      price: 0.042,
      model: 'BAV70',
      desc: 'FRED晶圆结构正向压降低，功耗低高浪涌电流能力塑料包装有保险商实验室易燃性分类94V- 0根据IEC 61249 - 2 - 21，无卤素'
    },
    {
      id: '112',
      categoryId: '378957',
      categoryName: '半导体',
      subCategoryId: '11',
      subCategoryName: '二极管',
      cover:'https://img2.baidu.com/it/u=1281915211,4053105941&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
      name: 'BAT54C',
      address: '广东省 深圳市',
      bank: 7200,
      brand: '南芯',
      price: 0.05,
      model: 'BAT54C',
      desc: 'FRED晶圆结构正向压降低，功耗低高浪涌电流能力塑料包装有保险商实验室易燃性分类94V- 0根据IEC 61249 - 2 - 21，无卤素'
    },
    {
      id: '113',
      categoryId: '378957',
      categoryName: '半导体',
      subCategoryId: '11',
      subCategoryName: '二极管',
      cover:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.alicdn.com%2Fbao%2Fuploaded%2Fi1%2F2206507821239%2FO1CN01Gsy1tf1L1VMvCQzyP_%21%210-item_pic.jpg_400x400.jpg&refer=http%3A%2F%2Fimg.alicdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1727226444&t=e036009ef88687802886e6b8697427a8',
      name: 'MMBT5401',
      address: '广东省 深圳市',
      bank: 7200,
      brand: '南芯',
      price: 0.05,
      model: 'MMBT5401',
      desc: '南芯二极管大量供应，价格优势。可提供技术支持欢迎咨询！'
    },
    {
      id: '121',
      categoryId: '378957',
      categoryName: '半导体',
      subCategoryId: '12',
      subCategoryName: '晶体管',
      cover:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.alicdn.com%2Fbao%2Fuploaded%2Fi1%2F2206507821239%2FO1CN01Gsy1tf1L1VMvCQzyP_%21%210-item_pic.jpg_400x400.jpg&refer=http%3A%2F%2Fimg.alicdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1727226444&t=e036009ef88687802886e6b8697427a8',
      name: '场效应管(MOSFET)',
      address: '广东省 深圳市',
      bank: 1125,
      brand: 'INFINEON/IR',
      price: 2.732,
      model: ' IRFU3410PBF',
      desc: 'High frequcency DC-DC converters二极管行业完善、齐全的设计、制造、封装、销售的厂商，从前端芯片的自主开发到后端成品的各种封装技术，形成了一个完整的产业链。主要产品包括封装技术的无引脚集成电路产品和分立器件产品、汽车整流二极管、功率模块、整流二极管芯片、硅整流二极管、开关二极管、稳压二极管、微型桥堆、军用熔断丝、光伏旁路模块等共有50多个系列、1500多个品种。产品广泛应用在航空航天、汽车、绿色照明、IT、家用电器以及大型设备的电源装置等许多领域。'
    },
    {
      id: '122',
      categoryId: '378957',
      categoryName: '半导体',
      subCategoryId: '12',
      subCategoryName: '晶体管',
      cover:'http://www.xindianyi.com/attachment/sys/05398e4bd25c9d6b8f2fc1db8c161f05.jpg',
      name: 'BC847BPN,115',
      address: '大陆',
      bank: 600000,
      brand: 'Nexperia(安世)',
      price: 0.39,
      model: 'BC847BPN',
      desc: '原装现货库存 欢迎下单咨询'
    },
    {
      id: '123',
      categoryId: '378957',
      categoryName: '半导体',
      subCategoryId: '12',
      subCategoryName: '晶体管',
      cover:'http://www.xindianyi.com/attachment/sys/05398e4bd25c9d6b8f2fc1db8c161f05.jpg',
      name: 'LPM9435SOF',
      address: '大陆',
      bank: 20000,
      brand: 'LOWPOWER(微源半导体)',
      price: 0.563,
      model: 'LPM9435SOF',
      desc: '	MOS(场效应管)'
    },
    {
      id: '124',
      categoryId: '378957',
      categoryName: '半导体',
      subCategoryId: '12',
      subCategoryName: '晶体管',
      cover:'http://www.xindianyi.com/attachment/sys/05398e4bd25c9d6b8f2fc1db8c161f05.jpg',
      name: '2N7002',
      address: '大陆',
      bank: 49876,
      brand: 'TWGMC(台湾迪嘉)',
      price: 0.122,
      model: '2N7002',
      desc: '	MOS(场效应管)'
    },
    {
      id: '151',
      categoryId: '378957',
      categoryName: '半导体',
      subCategoryId: '15',
      subCategoryName: '集成电路',
      cover:'http://www.xindianyi.com/attachment/sys/05398e4bd25c9d6b8f2fc1db8c161f05.jpg',
      name: 'TPS79901QDRVRQ1',
      address: '广东省 深圳市',
      bank: 78000,
      brand: 'TI',
      price: 3.80,
      model: 'TPS79901QDRVRQ1',
      desc: '半导体集成电路'
    },
    {
      id: '152',
      categoryId: '378957',
      categoryName: '半导体',
      subCategoryId: '15',
      subCategoryName: '集成电路',
      cover:'http://www.xindianyi.com/attachment/sys/05398e4bd25c9d6b8f2fc1db8c161f05.jpg',
      name: 'DRV5023BIQDBZRQ1',
      address: '广东省 深圳市',
      bank: 9500,
      brand: 'AVAGO',
      price: 0.10,
      model: 'DRV5023BIQDBZRQ1',
      desc: '半导体集成电路'
    },
    {
      id: '153',
      categoryId: '378957',
      categoryName: '半导体',
      subCategoryId: '15',
      subCategoryName: '集成电路',
      cover:'http://www.xindianyi.com/attachment/sys/05398e4bd25c9d6b8f2fc1db8c161f05.jpg',
      name: 'REF3433QDBVRQ1',
      address: '广东省 深圳市',
      bank: 10569,
      brand: 'MICROCHIP',
      price: 0.10,
      model: 'REF3433QDBVRQ1',
      desc: '集成电路   IC元器件'
    },
    {
      id: '154',
      categoryId: '378957',
      categoryName: '半导体',
      subCategoryId: '15',
      subCategoryName: '集成电路',
      cover:'http://www.xindianyi.com/attachment/sys/05398e4bd25c9d6b8f2fc1db8c161f05.jpg',
      name: '南方硅谷 SV6158（M版）',
      address: '广东省 深圳市',
      bank: 7741,
      brand: '南方硅谷',
      price: 5.0,
      model: 'SV6158',
      desc: '原装正品现货库存 价格优势热卖'
    },
    {
      id: '141',
      categoryId: '378957',
      categoryName: '半导体',
      subCategoryId: '14',
      subCategoryName: '放大器',
      cover:'http://www.xindianyi.com/attachment/sys/6cad3846d228b5f970356eda5fbfe0db.jpg',
      name: '射频放大器 GRF2051',
      address: '广东省 深圳市',
      bank: 3600,
      brand: 'Guerrilla RF',
      price: 30.569,
      model: 'GRF2051',
      desc: '安装风格:SMD/SMT封装 / 箱体:QFN-12类型:Ultra-Low Noise Amplifier技术:GaAs工作频率:700 MHz to 3.8 GHzP1dB - 压缩点:3.2 dBm增益:19.2 dB工作电源电压:2.7 V to 6 V'
    },
    {
      id: '142',
      categoryId: '378957',
      categoryName: '半导体',
      subCategoryId: '14',
      subCategoryName: '放大器',
      cover:'http://www.xindianyi.com/attachment/sys/c741c7fed2395ca00db71f2bdbd7f8ea.jpg',
      name: '射频放大器 GRF3042',
      address: '广东省 深圳市',
      bank: 1800,
      brand: 'Guerrilla RF',
      price: 33.787,
      model: 'GRF3042',
      desc: '安装风格:SMD/SMT封装 / 箱体:QFN-12类型:Ultra-Low Noise Amplifier技术:GaAs工作频率:700 MHz to 3.8 GHzP1dB - 压缩点:3.2 dBm增益:19.2 dB工作电源电压:2.7 V to 6 V'
    },
    {
      id: '143',
      categoryId: '378957',
      categoryName: '半导体',
      subCategoryId: '14',
      subCategoryName: '放大器',
      cover:'http://www.xindianyi.com/attachment/sys/c741c7fed2395ca00db71f2bdbd7f8ea.jpg',
      name: '射频放大器 GRF2003',
      address: '广东省 深圳市',
      bank: 1600,
      brand: 'Guerrilla RF',
      price: 32.178,
      model: 'GRF2003',
      desc: '安装风格:SMD/SMT封装 / 箱体:QFN-12类型:Ultra-Low Noise Amplifier技术:GaAs工作频率:700 MHz to 3.8 GHzP1dB - 压缩点:3.2 dBm增益:19.2 dB工作电源电压:2.7 V to 6 V'
    },
    {
      id: '144',
      categoryId: '378957',
      categoryName: '半导体',
      subCategoryId: '14',
      subCategoryName: '放大器',
      cover:'https://img0.baidu.com/it/u=48106781,107926824&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=400',
      name: '射频放大器 GRF2072',
      address: '广东省 深圳市',
      bank: 1600,
      brand: 'Guerrilla RF',
      price: 23.75,
      model: 'GRF2072',
      desc: '安装风格:SMD/SMT封装 / 箱体:QFN-12类型:Ultra-Low Noise Amplifier技术:GaAs工作频率:700 MHz to 3.8 GHzP1dB - 压缩点:3.2 dBm增益:19.2 dB工作电源电压:2.7 V to 6 V'
    },
    {
      id: '145',
      categoryId: '378957',
      categoryName: '半导体',
      subCategoryId: '14',
      subCategoryName: '放大器',
      cover:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fcbu01.alicdn.com%2Fimg%2Fibank%2F2020%2F303%2F935%2F23675539303_1772092590.jpg&refer=http%3A%2F%2Fcbu01.alicdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1727226714&t=77860c9ced93129e0efd8d1e33e15e6e',
      name: '射频低噪声放大器',
      address: '广东省 深圳市',
      bank: 1600,
      brand: 'ROHM',
      price: 1.1653,
      model: 'BA4580RF-E2',
      desc: '安装风格:SMD/SMT封装 / 箱体:QFN-12类型:Ultra-Low Noise Amplifier技术:GaAs工作频率:700 MHz to 3.8 GHzP1dB - 压缩点:3.2 dBm增益:19.2 dB工作电源电压:2.7 V to 6 V'
    },
    {
      id: '211',
      categoryId: '68734',
      categoryName: '光耦/发光管/红外',
      subCategoryId: '21',
      subCategoryName: '光耦',
      cover:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fcbu01.alicdn.com%2Fimg%2Fibank%2FO1CN01vRdejF1LGcRnzmArs_%21%213894071272-0-cib.jpg&refer=http%3A%2F%2Fcbu01.alicdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1727226729&t=1183a1701a512c3c82bffd73c7fdc7a4',
      name: 'ZPLR135/T8',
      address: '大陆',
      bank: 16,
      brand: 'Chau Light(洲光源)',
      price: 6.222,
      model: 'ZPLR135/T8',
      desc: '光纤收发器'
    },
    {
      id: '212',
      categoryId: '68734',
      categoryName: '光耦/发光管/红外',
      subCategoryId: '21',
      subCategoryName: '光耦',
      cover:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fcbu01.alicdn.com%2Fimg%2Fibank%2F2019%2F695%2F368%2F12871863596_2113539454.400x400.jpg&refer=http%3A%2F%2Fcbu01.alicdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1727226729&t=be366c1964d3e9c4bdb1ff81378b054c',
      name: '3S1020F7-AJ4-4F',
      address: '大陆',
      bank: 28,
      brand: 'FOXCONN(富士康)',
      price: 47.585,
      model: '3S1020F7-AJ4-4F',
      desc: '光纤收发器'
    },
    {
      id: '213',
      categoryId: '68734',
      categoryName: '光耦/发光管/红外',
      subCategoryId: '21',
      subCategoryName: '光耦',
      cover:'https://img0.baidu.com/it/u=1407829394,881405704&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
      name: 'HFBR-1532Z',
      address: '大陆',
      bank: 16,
      brand: 'AVAGO(安华高)',
      price: 59.812,
      model: 'HFBR-1532Z',
      desc: '光纤收发器'
    },
    {
      id: '214',
      categoryId: '68734',
      categoryName: '光耦/发光管/红外',
      subCategoryId: '21',
      subCategoryName: '光耦',
      cover:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fcbu01.alicdn.com%2Fimg%2Fibank%2F2019%2F353%2F076%2F11569670353_2041252489.jpg&refer=http%3A%2F%2Fcbu01.alicdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1727226793&t=4dc31dd9963dca1ef7b4430ce659f389',
      name: 'SLD1357CRC2BD-4.5',
      address: '大陆',
      bank: 333,
      brand: 'SUNLIGHT(光华)',
      price: 5.168,
      model: 'SLD1357CRC2BD-4.5',
      desc: '光纤收发器'
    },
    {
      id: '215',
      categoryId: '68734',
      categoryName: '光耦/发光管/红外',
      subCategoryId: '21',
      subCategoryName: '光耦',
      cover:'https://img0.baidu.com/it/u=4192319989,587570621&fm=253&fmt=auto&app=120&f=JPEG?w=342&h=342',
      name: 'LED点阵 FJ2088BH',
      address: '大陆',
      bank: 1469,
      brand: '志浩',
      price: 8.322,
      model: 'LED点阵 FJ2088BH',
      desc: 'LED点阵'
    },
    {
      id: '221',
      categoryId: '68734',
      categoryName: '光耦/发光管/红外',
      subCategoryId: '22',
      subCategoryName: '发光管',
      cover:'https://cos3.solepic.com/20201103/m_620795202011031453083335.jpg',
      name: '红色+黄绿色',
      address: '大陆',
      bank: 44084,
      brand: 'EVERLIGHT(台湾亿光)',
      price: 0.385,
      model: '12-22/R6G6C-C30/2C',
      desc: '光电固态继电器'
    },
    {
      id: '222',
      categoryId: '68734',
      categoryName: '光耦/发光管/红外',
      subCategoryId: '22',
      subCategoryName: '发光管',
      cover:'https://www.ireader-opto.cn/uploadfiles/pictures/news/20200924145512_8045.png',
      name: 'AQW212SX',
      address: '大陆',
      bank: 624,
      brand: 'PANASONIC(松下)',
      price: 23.949,
      model: 'AQW212SX',
      desc: '光电固态继电器'
    },
    {
      id: '223',
      categoryId: '68734',
      categoryName: '光耦/发光管/红外',
      subCategoryId: '22',
      subCategoryName: '发光管',
      cover:'https://uploadcdn.oneyac.com/attachments/files/brand_pdf/vishay/8F/88/VOW2611-X017T.JPG',
      name: 'KAQV414',
      address: '大陆',
      bank: 44084,
      brand: 'cosmo(冠西)',
      price: 14.449,
      model: 'KAQV414',
      desc: '光电固态继电器'
    },
    {
      id: '224',
      categoryId: '68734',
      categoryName: '光耦/发光管/红外',
      subCategoryId: '22',
      subCategoryName: '发光管',
      cover:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fcbu01.alicdn.com%2Fimg%2Fibank%2F2019%2F302%2F821%2F10378128203_559483441.jpg&refer=http%3A%2F%2Fcbu01.alicdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1727226805&t=805825f403a7205b8627ea4d16cf6ef1',
      name: 'KSD210AC8',
      address: '大陆',
      bank: 16,
      brand: 'cosmo(冠西)',
      price: 78.004,
      model: 'KSD210AC8',
      desc: '光电固态继电器'
    },
    {
      id: '225',
      categoryId: '68734',
      categoryName: '光耦/发光管/红外',
      subCategoryId: '22',
      subCategoryName: '发光管',
      cover:'https://img0.baidu.com/it/u=3999487565,768592668&fm=253&fmt=auto&app=138&f=JPEG?w=444&h=340',
      name: 'AQV258HAX',
      address: '大陆',
      bank: 36,
      brand: 'PANASONIC(松下)',
      price: 33.815,
      model: 'AQV258HAX',
      desc: '光电固态继电器'
    },
    {
      id: '226',
      categoryId: '68734',
      categoryName: '光耦/发光管/红外',
      subCategoryId: '22',
      subCategoryName: '发光管',
      cover:'https://img1.baidu.com/it/u=2682042904,1097662871&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=538',
      name: 'HFBR-4503Z 两件套',
      address: '大陆',
      bank: 1909,
      brand: 'AVAGO(安华高)',
      price: 4.892,
      model: 'HFBR-4503Z',
      desc: '光纤收发器'
    },
    {
      id: '231',
      categoryId: '68734',
      categoryName: '光耦/发光管/红外',
      subCategoryId: '23',
      subCategoryName: '红外',
      cover:'https://www.businesswirechina.com/files/news/SO16L_side.jpg',
      name: 'ZGY133/T9(PLT)',
      address: '大陆',
      bank: 90,
      brand: 'Chau Light(洲光源)',
      price: 3.638,
      model: 'ZGY133/T9(PLT)',
      desc: '光纤收发器'
    },
    {
      id: '231',
      categoryId: '68734',
      categoryName: '光耦/发光管/红外',
      subCategoryId: '23',
      subCategoryName: '红外',
      cover:'https://img1.baidu.com/it/u=434208204,496498737&fm=253&fmt=auto&app=138&f=JPEG?w=444&h=444',
      name: 'ZGY133/T9(PLT)',
      address: '大陆',
      bank: 90,
      brand: 'Chau Light(洲光源)',
      price: 3.638,
      model: 'ZGY133/T9(PLT)',
      desc: '光纤收发器'
    },{
      id: '232',
      categoryId: '68734',
      categoryName: '光耦/发光管/红外',
      subCategoryId: '23',
      subCategoryName: '红外',
      cover:'https://img.11467.com/2023/08-07/964338304.jpg',
      name: 'HFBR-2412TZ',
      address: '大陆',
      bank: 90,
      brand: 'AVAGO(安华高)',
      price: 74.926,
      model: 'HFBR-2412TZ',
      desc: '光纤收发器'
    },{
      id: '233',
      categoryId: '68734',
      categoryName: '光耦/发光管/红外',
      subCategoryId: '23',
      subCategoryName: '红外',
      cover:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fcbu01.alicdn.com%2Fimg%2Fibank%2FO1CN01vpYZyQ1JoOhWe9QGN_%21%212212011041075-0-cib.360x360.jpg&refer=http%3A%2F%2Fcbu01.alicdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1727226930&t=1ec8c91af3c07b96f02b556a764aff7f',
      name: 'ZGY133/T8(PLT)',
      address: '大陆',
      bank: 437,
      brand: 'Chau Light(洲光源)',
      price: 4.55,
      model: 'ZGY133/T8(PLT)',
      desc: '光纤收发器'
    },{
      id: '234',
      categoryId: '68734',
      categoryName: '光耦/发光管/红外',
      subCategoryId: '23',
      subCategoryName: '红外',
      cover:'https://img0.baidu.com/it/u=18820776,1228558267&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
      name: 'TFBS4652-TR1',
      address: '大陆',
      bank: 90,
      brand: 'VISHAY(威世)',
      price: 51.376,
      model: 'TFBS4652-TR1',
      desc: '	IrDA红外收发器'
    },{
      id: '235',
      categoryId: '68734',
      categoryName: '光耦/发光管/红外',
      subCategoryId: '23',
      subCategoryName: '红外',
      cover:'https://img2.baidu.com/it/u=899997561,1113172136&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
      name: 'HFBR-1521Z',
      address: '大陆',
      bank: 90,
      brand: 'AVAGO(安华高)',
      price: 46.274,
      model: 'HFBR-1521Z',
      desc: 'IrDA红外收发器'
    },
    {
      id: '311',
      categoryId: '34789',
      categoryName: '被动器件',
      subCategoryId: '31',
      subCategoryName: '电容',
      cover:'https://img0.baidu.com/it/u=3729472222,2933991720&fm=253&fmt=auto&app=138&f=JPEG?w=380&h=285',
      name: '贴片电解电容',
      address: '广东省 深圳市',
      bank:1000,
      brand: '荣誉',
      price: 0.22,
      model: 'SS1V330M-CRE45',
      desc: '33uF ±20% 6.3x4.5 35V '
    },
    {
      id: '312',
      categoryId: '34789',
      categoryName: '被动器件',
      subCategoryId: '31',
      subCategoryName: '电容',
      cover:'https://img0.baidu.com/it/u=2979744135,4108419218&fm=253&fmt=auto&app=120&f=JPEG?w=332&h=332',
      name: '贴片电解电容',
      address: '大陆',
      bank: 100000,
      brand: '荣誉',
      price: 0.115,
      model: 'RVT1A101M0505',
      desc: '100uF ±20% 5x5.4 10V '
    },
    {
      id: '313',
      categoryId: '34789',
      categoryName: '被动器件',
      subCategoryId: '31',
      subCategoryName: '电容',
      cover:'https://img2.baidu.com/it/u=4210596211,3560829128&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
      name: 'TLC2274AQPWRG4Q1',
      address: '大陆',
      bank: 2200,
      brand: 'QUECTEL',
      price: 0.100,
      model: 'TLC2274AQPWRG4Q1',
      desc: 'TSSOP-14-4.5mm'
    },{
      id: '321',
      categoryId: '34789',
      categoryName: '被动器件',
      subCategoryId: '32',
      subCategoryName: '电阻',
      cover:'https://img0.baidu.com/it/u=1666127861,3933624340&fm=253&fmt=auto&app=138&f=JPEG?w=392&h=429',
      name: 'NTC热敏电阻',
      address: '大陆',
      bank: 2200,
      brand: 'MURATA',
      price: 0.260,
      model: 'NCP18WB333J03RB',
      desc: 'NTC 热敏电阻器 33k 0603（1608 公制）'
    },{
      id: '322',
      categoryId: '34789',
      categoryName: '被动器件',
      subCategoryId: '32',
      subCategoryName: '电阻',
      cover:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fcbu01.alicdn.com%2Fimg%2Fibank%2FO1CN019Wrdrg2G56i8lXI6S_%21%213064648963-0-cib.jpg_620x10000.jpg&refer=http%3A%2F%2Fcbu01.alicdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1727227534&t=4e71905df58bcad2854e63829abfa98d',
      name: '金属釉厚膜车规贴片电阻',
      address: '广东省 广州市',
      bank: 50000,
      brand: 'KOA',
      price: 1.00,
      model: 'RK73H1ETTP4990F',
      desc: 'KOA一级代理 现货库存QQ800178669'
    },{
      id: '323',
      categoryId: '34789',
      categoryName: '被动器件',
      subCategoryId: '32',
      subCategoryName: '电阻',
      cover:'http://www.xindianyi.com/attachment/sys/684ec2919b13197e7dc5298e6386fbc5.jpg',
      name: 'RK73H1JTTD1003F  ',
      address: '广东省 深圳市',
      bank: 499999,
      brand: '日本KOA电阻器',
      price: 0.130,
      model: 'RK73H1JTTD1003F',
      desc: 'RK73H1JTTD1003F	100KΩ 0603 -1%~1% 0.1W,1/10W'
    },


    {
      id: '331',
      categoryId: '34789',
      categoryName: '被动器件',
      subCategoryId: '33',
      subCategoryName: '电感',
      cover:'http://www.xindianyi.com/attachment/sys/684ec2919b13197e7dc5298e6386fbc5.jpg',
      name: '固定电感器',
      address: '广东省 深圳市',
      bank: 429999,
      brand: 'MURATA',
      price: 0.260,
      model: 'LQW18AN20NG00D',
      desc: 'FIXED IND 20NH 550MA 160MOHM SMD'
    },{
      id: '332',
      categoryId: '34789',
      categoryName: '被动器件',
      subCategoryId: '33',
      subCategoryName: '电感',
      cover:'http://www.xindianyi.com/attachment/sys/684ec2919b13197e7dc5298e6386fbc5.jpg',
      name: '固定电感器-功率电感',
      address: '广东省 深圳市',
      bank: 499999,
      brand: '日本KOA电阻器',
      price: 0.960,
      model: 'LQW2BHNR12J03L',
      desc: '120 nH 无屏蔽 绕线 电感器 320 mA 400 毫欧最大 非标准'
    },{
      id: '333',
      categoryId: '34789',
      categoryName: '被动器件',
      subCategoryId: '33',
      subCategoryName: '电感',
      cover:'http://www.xindianyi.com/attachment/sys/684ec2919b13197e7dc5298e6386fbc5.jpg',
      name: 'EMI滤波器(RC,LC网络)',
      address: '广东省 深圳市',
      bank: 19999,
      brand: 'MURATA',
      price: 1.630,
      model: 'NFE61PT472C1H9L',
      desc: 'LC（T 型） EMI 滤波器 3rd 订单 低通 1 通道 C = 4700pF 2 2706（6816 公制），3 PC 板'
    },



    {
      id: '341',
      categoryId: '34789',
      categoryName: '被动器件',
      subCategoryId: '34',
      subCategoryName: '晶振',
      cover:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fcbu01.alicdn.com%2Fimg%2Fibank%2F2019%2F438%2F967%2F10872769834_1138713643.jpg&refer=http%3A%2F%2Fcbu01.alicdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1727227534&t=ea5560c7e766d86c1d76824fef5d78ed',
      name: 'TPS79901QDRVRQ1',
      address: '广东省 深圳市',
      bank: 999,
      brand: 'MURATA',
      price: 3.800,
      model: 'TPS79901QDRVRQ1',
      desc: 'LC（T 型） EMI 滤波器 3rd 订单 低通 1 通道 C = 4700pF 2 2706（6816 公制），3 PC 板'
    },{
      id: '342',
      categoryId: '34789',
      categoryName: '被动器件',
      subCategoryId: '34',
      subCategoryName: '晶振',
      cover:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.alicdn.com%2Fi3%2F2301578421%2FO1CN01j83KE42C4rqwHbJnb_%21%212301578421.jpg&refer=http%3A%2F%2Fimg.alicdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1727227534&t=7bfc94214b2b683deff2bbc7bc5ed027',
      name: 'SSM3J35CT,L3F(T',
      address: '广东省 深圳市',
      bank: 1799,
      brand: 'TOSHIBA',
      price: 1.630,
      model: 'SSM3J35CT,L3F(T',
      desc: 'LC（T 型） EMI 滤波器 3rd 订单 低通 1 通道 C = 4700pF 2 2706（6816 公制），3 PC 板'
    },{
      id: '343',
      categoryId: '34789',
      categoryName: '被动器件',
      subCategoryId: '34',
      subCategoryName: '晶振',
      cover:'https://img2.baidu.com/it/u=3277715305,2556528080&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
      name: 'SN74LVC2G17QDCKRQ1',
      address: '广东省 深圳市',
      bank: 999,
      brand: 'MURATA',
      price: 0.100,
      model: 'SN74LVC2G17QDCKRQ1',
      desc: 'LC（T 型） EMI 滤波器 3rd 订单 低通 1 通道 C = 4700pF 2 2706（6816 公制），3 PC 板'
    },


    {
      id: '351',
      categoryId: '34789',
      categoryName: '被动器件',
      subCategoryId: '35',
      subCategoryName: '继电器',
      cover:'https://t11.baidu.com/it/u=3996444235,177584931&fm=30&app=106&f=JPEG?w=640&h=480&s=3B8854201DCA56C20670B4D40100E0A3',
      name: 'RSL116091',
      address: '大陆',
      bank: 91991,
      brand: 'MURATA',
      price: 441.275,
      model: 'RSL116091',
      desc: '配套使用/相关产品	M83536 继电器'
    },{
      id: '352',
      categoryId: '34789',
      categoryName: '被动器件',
      subCategoryId: '35',
      subCategoryName: '继电器',
      cover:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fcbu01.alicdn.com%2Fimg%2Fibank%2FO1CN01T7oU3j1yAumDV5fMC_%21%212211598636539-0-cib.jpg&refer=http%3A%2F%2Fcbu01.alicdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1727227534&t=5a4e0b7357cbf21e2c1450cb8a0cb9ba',
      name: 'S PS',
      address: '大陆',
      bank: 11399,
      brand: 'PANASONIC(松下)',
      price: 11.580,
      model: 'S-PS',
      desc: '配套使用/相关产品	S 系列'
    },{
      id: '353',
      categoryId: '34789',
      categoryName: '被动器件',
      subCategoryId: '35',
      subCategoryName: '继电器',
      cover:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.alicdn.com%2Fi1%2F12762090%2FO1CN016Q9dld1RJGPgj4zp3_%21%2112762090.jpg&refer=http%3A%2F%2Fimg.alicdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1727227534&t=b1794581eef4e987d7db6533f7a955e8',
      name: '固态继电器',
      address: '广东省 深圳市',
      bank: 91949,
      brand: 'INFINEON',
      price: 39.663,
      model: 'PVT212SPBF',
      desc: 'LC（T 型） EMI 滤波器 3rd 订单 低通 1 通道 C = 4700pF 2 2706（6816 公制），3 PC 板'
    },



    {
      id: '361',
      categoryId: '34789',
      categoryName: '被动器件',
      subCategoryId: '36',
      subCategoryName: '保险丝',
      cover:'https://img0.baidu.com/it/u=2530231938,2278055343&fm=253&fmt=auto&app=138&f=JPEG?w=475&h=356',
      name: '80A 60mV 1%',
      address: '大陆',
      bank: 9191,
      brand: 'Amphenol FCI',
      price: 441.275,
      model: 'UBH-U-80-60F-t-W',
      desc: '商品目录	分流器'
    },{
      id: '362',
      categoryId: '34789',
      categoryName: '被动器件',
      subCategoryId: '36',
      subCategoryName: '保险丝',
      cover:'https://img0.baidu.com/it/u=1788781323,1049836735&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
      name: '车规级精密分流器',
      address: '大陆',
      bank: 199,
      brand: 'Resistor.Today(开步睿思)',
      price: 19.266,
      model: 'ARCS6918JL100B9L',
      desc: '额定压降	50mV'
    },{
      id: '363',
      categoryId: '34789',
      categoryName: '被动器件',
      subCategoryId: '36',
      subCategoryName: '保险丝',
      cover:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.alicdn.com%2Fi1%2F1070042715%2FTB2fixXuOMnBKNjSZFCXXX0KFXa_%21%211070042715.jpg&refer=http%3A%2F%2Fimg.alicdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1727228528&t=f6c6b1058b6ec0306af0df716986e41f',
      name: '1mΩ ±3%',
      address: '广东省 深圳市',
      bank: 949,
      brand: 'yezhan(业展)',
      price: 2.584,
      model: 'ASR-M-5-1-3%',
      desc: '分流器'
    },



    {
      id: '411',
      categoryId: '93843',
      categoryName: '连接器',
      subCategoryId: '41',
      subCategoryName: 'FFC/FPC连接器',
      cover:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.alicdn.com%2Fi1%2F12762090%2FO1CN01Y1JW5n1RJGTHP88hI_%21%2112762090.jpg&refer=http%3A%2F%2Fimg.alicdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1727228567&t=87a85c9195d53b527203f7dd53b10c5d',
      name: 'IC插座',
      address: '浙江省 宁波市',
      bank: 11191,
      brand: 'connfly',
      price: 0.084,
      model: 'DS1009-14AT1NX-OA2',
      desc: '14P,不打kin脚，端子镀锡，7.62宽，无杠，PBT V0料,管装塞头，一装二'
    },{
      id: '412',
      categoryId: '93843',
      categoryName: '连接器',
      subCategoryId: '41',
      subCategoryName: 'FFC/FPC连接器',
      cover:'http://t15.baidu.com/it/u=1228400805,2622502017&fm=224&app=112&f=JPEG?w=500&h=500',
      name: 'IC插座',
      address: '所在区域：',
      bank: 11399,
      brand: 'connfly',
      price: 0.096,
      model: 'DS1009-16AT1NX-OA2',
      desc: '16P,不打kin脚，端子镀锡，7.62宽，无杠，PBT V0料,管装塞头，一装二'
    },{
      id: '413',
      categoryId: '93843',
      categoryName: '连接器',
      subCategoryId: '41',
      subCategoryName: 'FFC/FPC连接器',
      cover:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.alicdn.com%2Fi3%2F2206379511510%2FO1CN01mWFAsl1N1ccU2xQey_%21%212206379511510.jpg&refer=http%3A%2F%2Fimg.alicdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1727228585&t=9d9ce746678867fad948b6bd10f248e8',
      name: 'IC插座',
      address: '浙江省 宁波市',
      bank: 71949,
      brand: 'connfly',
      price: 0.239,
      model: 'DS1009-40AT1WX-0A2',
      desc: '40P,不打kin脚，端子全锡，15.24宽，无杠，PBT V0料,管装塞头,一装二'
    },



    {
      id: '421',
      categoryId: '93843',
      categoryName: '连接器',
      subCategoryId: '42',
      subCategoryName: '线到线连接器',
      cover:'https://img0.baidu.com/it/u=1395112838,10207185&fm=253&fmt=auto&app=138&f=JPEG?w=672&h=500',
      name: 'IDC插座',
      address: '浙江省 宁波市',
      bank: 1111,
      brand: 'connfly',
      price: 0.121,
      model: 'DS1016-08MANBB',
      desc: '2.54mm IDC 8Pin 有鼻子，有上盖，全镍，黑色'
    },{
      id: '422',
      categoryId: '93843',
      categoryName: '连接器',
      subCategoryId: '42',
      subCategoryName: '线到线连接器',
      cover:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fcbu01.alicdn.com%2Fimg%2Fibank%2F2018%2F720%2F743%2F9143347027_626347277.jpg&refer=http%3A%2F%2Fcbu01.alicdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1727228619&t=efc7a9881469499aebbc3dddd8ab2433',
      name: '排母',
      address: '所在区域：',
      bank: 11399,
      brand: 'connfly',
      price: 0.525,
      model: 'DS1023-02-2x13S21',
      desc: '2.54mm 排母2*13pin 直针，8.5mm高黑色，镀半金镍'
    },{
      id: '423',
      categoryId: '93843',
      categoryName: '连接器',
      subCategoryId: '42',
      subCategoryName: '线到线连接器',
      cover:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.alicdn.com%2Fi1%2F1812347392%2FTB2G2_.xXGWBuNjy0FbXXb4sXXa_%21%211812347392.jpg&refer=http%3A%2F%2Fimg.alicdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1727228619&t=6d32f308578a71a517c413ea5f5fc3d0',
      name: 'IDC插座',
      address: '浙江省 宁波市',
      bank: 7849,
      brand: 'connfly',
      price: 0.969,
      model: 'DS1018-402BX',
      desc: '2.54 DIP DC4-40P黑色，半金镍，不打Kink脚'
    },
    {
      id: '431',
      categoryId: '93843',
      categoryName: '连接器',
      subCategoryId: '43',
      subCategoryName: '电源连接器',
      cover:'https://web.ruidan.com/images/product/616/682/7698542.jpg',
      name: '5.9无柱MICRO USB插座 无卷边',
      address: '广东省 东莞市',
      bank: 5000,
      brand: 'XUNPU',
      price: 0.21,
      model: 'MU-103-ARW59',
      desc: '5.9无柱MICRO USB插座 无卷边'
    },
    {
      id: '432',
      categoryId: '93843',
      categoryName: '连接器',
      subCategoryId: '43',
      subCategoryName: '电源连接器',
      cover:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.alicdn.com%2Fi4%2F1026430339%2FTB2lWUecpXXXXaCXpXXXXXXXXXX_%21%211026430339.jpg&refer=http%3A%2F%2Fimg.alicdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1727228619&t=e194348ec1fe4856a6af008d02100799',
      name: '180度 B型母座 铁壳',
      address: '广东省 东莞市',
      bank: 5000,
      brand: 'XUNPU',
      price: 0.82,
      model: 'USB-B12-BRW',
      desc: '180度 B型母座 铁壳'
    },
    {
      id: '433',
      categoryId: '93843',
      categoryName: '连接器',
      subCategoryId: '43',
      subCategoryName: '电源连接器',
      cover:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.alicdn.com%2Fbao%2Fuploaded%2Fi2%2F3175405527%2FO1CN01ssrx7P1qhPfHW6eBP_%21%213175405527.jpg&refer=http%3A%2F%2Fimg.alicdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1727228619&t=d387446dd687ea4eb4b919bb3ef4a166',
      name: '6.4有柱MICRO USB插座 卷边',
      address: '广东省 东莞市',
      bank: 5000,
      brand: 'XUNPU',
      price: 0.21,
      model: 'MU-103-ACY64',
      desc: '6.4有柱MICRO USB插座 卷边'
    },
    {
      id: '441',
      categoryId: '93843',
      categoryName: '连接器',
      subCategoryId: '44',
      subCategoryName: 'D-sub',
      cover:'https://web.ruidan.com/images/product/616/682/7690994.jpg',
      name: '内芯1 1A电流 DC电源插座',
      address: '广东省 东莞市',
      bank: 5000,
      brand: 'XUNPU',
      price: 0.542,
      model: 'DC-031A-A100',
      desc: '内芯1 1A电流 DC电源插座'
    },
    {
      id: '442',
      categoryId: '93843',
      categoryName: '连接器',
      subCategoryId: '44',
      subCategoryName: 'D-sub',
      cover:'https://file.elecfans.com/web2/M00/98/8D/pYYBAGQQPeWAcX6_AAk3OjxEjlA459.png',
      name: '内芯2 10A电流 DC电源插座',
      address: '广东省 东莞市',
      bank: 5000,
      brand: 'XUNPU',
      price: 1.8,
      model: 'DC-091-A200',
      desc: '内芯2 10A电流 DC电源插座'
    },
    {
      id: '443',
      categoryId: '93843',
      categoryName: '连接器',
      subCategoryId: '44',
      subCategoryName: 'D-sub',
      cover:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fcbu01.alicdn.com%2Fimg%2Fibank%2F2019%2F969%2F830%2F12508038969_521787833.jpg&refer=http%3A%2F%2Fcbu01.alicdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1727228994&t=1335e39df7b110ae5953c96781e8dc7e',
      name: '内芯2.5 3A电流 DC电源插座',
      address: '广东省 东莞市',
      bank: 5000,
      brand: 'XUNPU',
      price: 0.48,
      model: 'DC-012A-B250',
      desc: '内芯2.5 3A电流 DC电源插座'
    },
    {
      id: '451',
      categoryId: '93843',
      categoryName: '连接器',
      subCategoryId: '45',
      subCategoryName: '板到板连接器',
      cover:'http://www.xindianyi.com/attachment/sys/34c853dd40b3af753359d782d431b021.jpg',
      name: '8P翻盖式MICRO SIM卡座',
      address: '广东省 东莞市',
      bank: 5000,
      brand: 'XUNPU',
      price: 0.93,
      model: 'SMC-220-ARP8',
      desc: '8P翻盖式MICRO SIM卡座'
    },
    {
      id: '452',
      categoryId: '93843',
      categoryName: '连接器',
      subCategoryId: '45',
      subCategoryName: '板到板连接器',
      cover:'http://www.xindianyi.com/attachment/sys/ad1cb708d1b589fd168b19c60c174039.jpg',
      name: '7P自弹式NANO SIM卡座',
      address: '广东省 东莞市',
      bank: 5000,
      brand: 'XUNPU',
      price: 1.07,
      model: 'SMN-303-ACP7',
      desc: '7P自弹式NANO SIM卡座'
    },
    {
      id: '453',
      categoryId: '93843',
      categoryName: '连接器',
      subCategoryId: '45',
      subCategoryName: '板到板连接器',
      cover:'http://www.xindianyi.com/attachment/sys/28847c08c6a2f8289c557b536671ed8a.jpg',
      name: '6P推拉式NANO SIM卡座',
      address: '广东省 东莞市',
      bank: 5000,
      brand: 'XUNPU',
      price: 1.84,
      model: 'SMN-304-ACP6',
      desc: '6P推拉式NANO SIM卡座'
    },
    {
      id: '461',
      categoryId: '93843',
      categoryName: '连接器',
      subCategoryId: '46',
      subCategoryName: '线到板连接器',
      cover:'http://www.xindianyi.com/attachment/sys/c093a9ce0438075fd6ef69da01c499fd.jpg',
      name: '1.68H 自弹式TF卡座',
      address: '广东省 东莞市',
      bank: 5000,
      brand: 'XUNPU',
      price: 2.04,
      model: 'TF-111-ARP10',
      desc: '1.68H 自弹式TF卡座'
    },
    {
      id: '462',
      categoryId: '93843',
      categoryName: '连接器',
      subCategoryId: '46',
      subCategoryName: '线到板连接器',
      cover:'https://img2.baidu.com/it/u=4190197769,1503127977&fm=253&fmt=auto&app=138&f=PNG?w=500&h=500',
      name: 'RF射频 1/2代IPEX板端天线座',
      address: '广东省 东莞市',
      bank: 5000,
      brand: 'XUNPU',
      price: 0.38 ,
      model: 'SP-RF-001',
      desc: 'RF射频 1/2代IPEX板端天线座'
    },
    {
      id: '463',
      categoryId: '93843',
      categoryName: '连接器',
      subCategoryId: '46',
      subCategoryName: '线到板连接器',
      cover:'https://img.bj.wezhan.cn/content/sitefiles/2022211/images/12226110_097.jpeg',
      name: 'TMP6131DYAR',
      address: '广东省 东莞市',
      bank: 5000,
      brand: 'XUNPU',
      price: 1.415,
      model: 'TMP6131DYAR',
      desc: '具有正温度系数 (PTC) 的硅基热敏电阻'
    },
    {
      id: '511',
      categoryId: '934843',
      categoryName: '开发工具',
      subCategoryId: '51',
      subCategoryName: '树莓派',
      cover:'https://web.ruidan.com/images/product/458/460/3807353.jpg',
      name: '固定电感器',
      address: '广东省 东莞市',
      bank: 5783,
      brand: 'MURATA',
      price: 0.26,
      model: 'LQW18AN20NG00D',
      desc: '20 nH 电感器 550 mA'
    },
    {
      id: '512',
      categoryId: '934843',
      categoryName: '开发工具',
      subCategoryId: '51',
      subCategoryName: '树莓派',
      cover:'https://web.ruidan.com/images/product/458/460/3837542.jpg',
      name: '固定电感器-功率电感',
      address: '广东省 东莞市',
      bank: 5783,
      brand: 'MURATA',
      price: 0.96,
      model: 'LQW2BHNR12J03L',
      desc: '20 nH 无屏蔽 绕线 电感器 320 mA 400 毫欧最大 非标准'
    },
    {
      id: '513',
      categoryId: '934843',
      categoryName: '开发工具',
      subCategoryId: '51',
      subCategoryName: '树莓派',
      cover:'https://web.ruidan.com/images/product/458/460/3801434.jpg',
      name: 'EMI滤波器(RC,LC网络)',
      address: '广东省 东莞市',
      bank: 5783,
      brand: 'MURATA',
      price: 1.63,
      model: 'NFE61PT472C1H9L',
      desc: 'LC（T 型） EMI 滤波器 3rd 订单 低通 1 通道 C = 4700pF 2 2706（6816 公制），3 PC 板'
    },
    {
      id: '521',
      categoryId: '934843',
      categoryName: '开发工具',
      subCategoryId: '52',
      subCategoryName: '开发板',
      cover:'http://t15.baidu.com/it/u=186044685,1972377672&fm=224&app=112&f=JPEG?w=500&h=500',
      name: '三选二双层卡座',
      address: '广东省 东莞市',
      bank: 5000,
      brand: 'MURATA',
      price: 5.37,
      model: 'MCS-304',
      desc: '三选二双层卡座'
    },
    {
      id: '522',
      categoryId: '934843',
      categoryName: '开发工具',
      subCategoryId: '52',
      subCategoryName: '开发板',
      cover:'http://www.xindianyi.com/attachment/sys/12fb5da3d1421d7c0478787f36b5d296.jpg',
      name: '村田磁珠代理',
      address: '广东省 东莞市',
      bank: 5000,
      brand: '村田',
      price: 0.01,
      model: ' BLM18KG221SN1D',
      desc: '贴片磁珠 BLM18KG221SN1D 0603 220Ω±25%'
    },
    {
      id: '523',
      categoryId: '934843',
      categoryName: '开发工具',
      subCategoryId: '52',
      subCategoryName: '开发板',
      cover:'http://www.xindianyi.com/attachment/sys/3bbecff7d7ddc5721276bca98a42ce01.jpg',
      name: '村田电感代理商',
      address: '广东省 东莞市',
      bank: 5000,
      brand: '村田',
      price: 0.10,
      model: 'LQH44PN1R0NP0L',
      desc: '村田电感代理商'
    },
    {
      id: '531',
      categoryId: '934843',
      categoryName: '开发工具',
      subCategoryId: '53',
      subCategoryName: '外设模块',
      cover:'https://web.ruidan.com/images/product/458/460/3763252.jpg',
      name: '固定电感器',
      address: '广东省 东莞市',
      bank: 2000,
      brand: 'MURATA',
      price: 0.74,
      model: 'LQW2BHN82NJ03L',
      desc: '82 nH 无屏蔽 绕线 电感器 320 mA 420 毫欧最大 非标准'
    },
    {
      id: '532',
      categoryId: '934843',
      categoryName: '开发工具',
      subCategoryId: '53',
      subCategoryName: '外设模块',
      cover:'http://t14.baidu.com/it/u=3180422949,3622707192&fm=224&app=112&f=JPEG?w=500&h=500',
      name: '固定电感器',
      address: '广东省 东莞市',
      bank: 2000,
      brand: 'MURATA',
      price: 0.74,
      model: 'LQW2BHN82NJ03L',
      desc: '82 nH 无屏蔽 绕线 电感器 320 mA 420 毫欧最大 非标准'
    },
    {
      id: '533',
      categoryId: '934843',
      categoryName: '开发工具',
      subCategoryId: '53',
      subCategoryName: '外设模块',
      cover:'http://t15.baidu.com/it/u=2807145593,4198935503&fm=224&app=112&f=JPEG?w=500&h=500',
      name: '电感 SWPA252012S2R2MT',
      address: '广东省 东莞市',
      bank: 2000,
      brand: 'Sunlord',
      price: 0.50,
      model: 'SWPA252012S2R2MT',
      desc: '原装正品'
    },
    {
      id: '541',
      categoryId: '934843',
      categoryName: '开发工具',
      subCategoryId: '54',
      subCategoryName: '烧录器',
      cover:'https://img0.baidu.com/it/u=2494329038,135266009&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
      name: '一体成型电感',
      address: '广东省 东莞市',
      bank: 100,
      brand: 'COILMX',
      price: 0.36,
      model: 'MS0603-4R7M',
      desc: '一体成型电感'
    },
    {
      id: '542',
      categoryId: '934843',
      categoryName: '开发工具',
      subCategoryId: '54',
      subCategoryName: '烧录器',
      cover:'http://www.xindianyi.com/attachment/sys/9cde406107e5e2a795b2b416792c940a.jpg',
      name: '电感 SWPA5020S150MT',
      address: '广东省 东莞市',
      bank: 100,
      brand: 'COILMX',
      price: 0.50,
      model: 'SWPA5020S150MT',
      desc: '一体成型电感'
    },
    {
      id: '543',
      categoryId: '934843',
      categoryName: '开发工具',
      subCategoryId: '54',
      subCategoryName: '烧录器',
      cover:'http://www.xindianyi.com/attachment/sys/5fc1ed66bc3d0000e872fb94bc3e0653.jpg',
      name: '一体成型电感',
      address: '广东省 东莞市',
      bank: 100,
      brand: 'COILMX',
      price: 0.64,
      model: 'MS1040-4R7M',
      desc: '一体成型电感'
    },
    {
      id: '551',
      categoryId: '934843',
      categoryName: '开发工具',
      subCategoryId: '55',
      subCategoryName: '焊接工具',
      cover:'http://www.xindianyi.com/attachment/sys/74cada03deb9ed570887a8e296f063af.jpg',
      name: '功率电感',
      address: '广东省 东莞市',
      bank: 100,
      brand: 'COILMX',
      price: 0.22,
      model: 'MSA75-2R2M',
      desc: '功率电感'
    },
    {
      id: '552',
      categoryId: '934843',
      categoryName: '开发工具',
      subCategoryId: '55',
      subCategoryName: '焊接工具',
      cover:'http://www.xindianyi.com/attachment/sys/d4ba7d20d560ed090640213b5c7c0375.jpg',
      name: '功率电感',
      address: '广东省 东莞市',
      bank: 100,
      brand: 'COILMX',
      price: 0.22,
      model: 'MSA75-3R3M',
      desc: '功率电感'
    },
    {
      id: '553',
      categoryId: '934843',
      categoryName: '开发工具',
      subCategoryId: '55',
      subCategoryName: '焊接工具',
      cover:'https://img2.baidu.com/it/u=134890725,3371478678&fm=253&fmt=auto&app=138&f=JPEG?w=681&h=500',
      name: '功率电感',
      address: '广东省 东莞市',
      bank: 100,
      brand: 'COILMX',
      price: 0.22,
      model: 'MSA75-4R7M',
      desc: '功率电感'
    },

    {
      id: '611',
      categoryId: '87906',
      categoryName: '仪器仪表',
      subCategoryId: '61',
      subCategoryName: '电子测量仪器',
      cover:'https://img2.baidu.com/it/u=1368194980,731255306&fm=253&fmt=auto&app=138&f=JPEG?w=691&h=500',
      name: '固定电感器',
      address: '广东省 东莞市',
      bank: 5783,
      brand: 'MURATA',
      price: 0.26,
      model: 'LQW18AN20NG00D',
      desc: '20 nH 电感器 550 mA'
    },
    {
      id: '612',
      categoryId: '87906',
      categoryName: '仪器仪表',
      subCategoryId: '61',
      subCategoryName: '电子测量仪器',
      cover:'https://img2.baidu.com/it/u=2530033905,856182389&fm=253&fmt=auto&app=138&f=JPEG?w=499&h=317',
      name: '固定电感器-功率电感',
      address: '广东省 东莞市',
      bank: 5783,
      brand: 'MURATA',
      price: 0.96,
      model: 'LQW2BHNR12J03L',
      desc: '20 nH 无屏蔽 绕线 电感器 320 mA 400 毫欧最大 非标准'
    },
    {
      id: '613',
      categoryId: '87906',
      categoryName: '仪器仪表',
      subCategoryId: '61',
      subCategoryName: '电子测量仪器',
      cover:'https://img2.baidu.com/it/u=2876390049,1163081108&fm=253&fmt=auto&app=138&f=JPEG?w=771&h=500',
      name: 'EMI滤波器(RC,LC网络)',
      address: '广东省 东莞市',
      bank: 5783,
      brand: 'MURATA',
      price: 1.63,
      model: 'NFE61PT472C1H9L',
      desc: 'LC（T 型） EMI 滤波器 3rd 订单 低通 1 通道 C = 4700pF 2 2706（6816 公制），3 PC 板'
    },
    {
      id: '621',
      categoryId: '87906',
      categoryName: '仪器仪表',
      subCategoryId: '62',
      subCategoryName: '光学检测仪器',
      cover:'https://img1.baidu.com/it/u=2890951724,674398007&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=417',
      name: '三选二双层卡座',
      address: '广东省 东莞市',
      bank: 5000,
      brand: 'MURATA',
      price: 5.37,
      model: 'MCS-304',
      desc: '三选二双层卡座'
    },
    {
      id: '622',
      categoryId: '87906',
      categoryName: '仪器仪表',
      subCategoryId: '62',
      subCategoryName: '光学检测仪器',
      cover:'http://www.xindianyi.com/attachment/sys/12fb5da3d1421d7c0478787f36b5d296.jpg',
      name: '村田磁珠代理',
      address: '广东省 东莞市',
      bank: 5000,
      brand: '村田',
      price: 0.01,
      model: ' BLM18KG221SN1D',
      desc: '贴片磁珠 BLM18KG221SN1D 0603 220Ω±25%'
    },
    {
      id: '623',
      categoryId: '87906',
      categoryName: '仪器仪表',
      subCategoryId: '62',
      subCategoryName: '光学检测仪器',
      cover:'http://www.xindianyi.com/attachment/sys/3bbecff7d7ddc5721276bca98a42ce01.jpg',
      name: '村田电感代理商',
      address: '广东省 东莞市',
      bank: 5000,
      brand: '村田',
      price: 0.10,
      model: 'LQH44PN1R0NP0L',
      desc: '村田电感代理商'
    },
    {
      id: '631',
      categoryId: '87906',
      categoryName: '仪器仪表',
      subCategoryId: '63',
      subCategoryName: '气体检测仪器',
      cover:'https://img2.baidu.com/it/u=3728964183,611183063&fm=253&fmt=auto&app=138&f=JPEG?w=667&h=500',
      name: '固定电感器',
      address: '广东省 东莞市',
      bank: 2000,
      brand: 'MURATA',
      price: 0.74,
      model: 'LQW2BHN82NJ03L',
      desc: '82 nH 无屏蔽 绕线 电感器 320 mA 420 毫欧最大 非标准'
    },
    {
      id: '632',
      categoryId: '87906',
      categoryName: '仪器仪表',
      subCategoryId: '63',
      subCategoryName: '气体检测仪器',
      cover:'https://img0.baidu.com/it/u=3174301967,2464469688&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',
      name: '固定电感器',
      address: '广东省 东莞市',
      bank: 2000,
      brand: 'MURATA',
      price: 0.74,
      model: 'LQW2BHN82NJ03L',
      desc: '82 nH 无屏蔽 绕线 电感器 320 mA 420 毫欧最大 非标准'
    },
    {
      id: '633',
      categoryId: '87906',
      categoryName: '仪器仪表',
      subCategoryId: '63',
      subCategoryName: '气体检测仪器',
      cover:'https://img2.baidu.com/it/u=2833226945,562642692&fm=253&fmt=auto&app=138&f=JPEG?w=256&h=256',
      name: '电感 SWPA252012S2R2MT',
      address: '广东省 东莞市',
      bank: 2000,
      brand: 'Sunlord',
      price: 0.50,
      model: 'SWPA252012S2R2MT',
      desc: '原装正品'
    },
    {
      id: '641',
      categoryId: '87906',
      categoryName: '仪器仪表',
      subCategoryId: '64',
      subCategoryName: '力学检测仪器',
      cover:'http://t15.baidu.com/it/u=2807145593,4198935503&fm=224&app=112&f=JPEG?w=500&h=500',
      name: '一体成型电感',
      address: '广东省 东莞市',
      bank: 100,
      brand: 'COILMX',
      price: 0.36,
      model: 'MS0603-4R7M',
      desc: '一体成型电感'
    },
    {
      id: '642',
      categoryId: '87906',
      categoryName: '仪器仪表',
      subCategoryId: '64',
      subCategoryName: '力学检测仪器',
      cover:'http://www.xindianyi.com/attachment/sys/9cde406107e5e2a795b2b416792c940a.jpg',
      name: '电感 SWPA5020S150MT',
      address: '广东省 东莞市',
      bank: 100,
      brand: 'COILMX',
      price: 0.50,
      model: 'SWPA5020S150MT',
      desc: '一体成型电感'
    },
    {
      id: '643',
      categoryId: '87906',
      categoryName: '仪器仪表',
      subCategoryId: '64',
      subCategoryName: '力学检测仪器',
      cover:'http://www.xindianyi.com/attachment/sys/5fc1ed66bc3d0000e872fb94bc3e0653.jpg',
      name: '一体成型电感',
      address: '广东省 东莞市',
      bank: 100,
      brand: 'COILMX',
      price: 0.64,
      model: 'MS1040-4R7M',
      desc: '一体成型电感'
    },
    {
      id: '651',
      categoryId: '87906',
      categoryName: '仪器仪表',
      subCategoryId: '65',
      subCategoryName: '环境测量仪器',
      cover:'http://www.xindianyi.com/attachment/sys/74cada03deb9ed570887a8e296f063af.jpg',
      name: '功率电感',
      address: '广东省 东莞市',
      bank: 100,
      brand: 'COILMX',
      price: 0.22,
      model: 'MSA75-2R2M',
      desc: '功率电感'
    },
    {
      id: '652',
      categoryId: '87906',
      categoryName: '仪器仪表',
      subCategoryId: '65',
      subCategoryName: '环境测量仪器',
      cover:'http://www.xindianyi.com/attachment/sys/d4ba7d20d560ed090640213b5c7c0375.jpg',
      name: '功率电感',
      address: '广东省 东莞市',
      bank: 100,
      brand: 'COILMX',
      price: 0.22,
      model: 'MSA75-3R3M',
      desc: '功率电感'
    },
    {
      id: '653',
      categoryId: '87906',
      categoryName: '仪器仪表',
      subCategoryId: '65',
      subCategoryName: '环境测量仪器',
      cover:'http://www.xindianyi.com/attachment/sys/0c0bce2263c1da2feca2f79e2cd5a44a.jpg',
      name: '功率电感',
      address: '广东省 东莞市',
      bank: 100,
      brand: 'COILMX',
      price: 0.22,
      model: 'MSA75-4R7M',
      desc: '功率电感'
    },


  ]
}