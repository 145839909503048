import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home/index',
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginPage.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/RegisterPage.vue"),
  },
  {
    path: "goods-list",
    name: "/home/goods-list",
    component: () => import("../views/GoodsList.vue"),
  },
  {
    path:'/home',
    name:'home',
    component: HomeView,
    children: [
      {
        path: "index",
        name: "/home/index",
        component: () => import("../views/IndexView.vue"),
      },
      {
        path: "product",
        name: "/home/product",
        component: () => import("../views/ProductLibrary.vue"),
      },
      {
        path: "date",
        name: "/home/date",
        component: () => import("../views/TechnicalDate.vue"),
      },
      {
        path: "news",
        name: "/home/news",
        component: () => import("../views/IndustryNews.vue"),
      },
      {
        path: "free",
        name: "/home/free",
        component: () => import("../views/FreeSamples.vue"),
      },
      {
        path: "goods",
        name: "/home/goods",
        component: () => import("../views/goodsDetail.vue"),
      },
      {
        path: "news-detail",
        name: "/home/news-detail",
        component: () => import("../views/NewsDetail.vue"),
      },
      {
        path: "techical-detail",
        name: "/home/techical-detail",
        component: () => import("../views/TechicalDetail.vue"),
      },
      {
        path: "order-list",
        name: "/home/order-list",
        component: () => import("../views/OrderList.vue"),
      },
      {
        path: "my-center",
        name: "/home/my-center",
        component: () => import("../views/MyCenter.vue"),
      },
    
      ]
  }

]

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
})

export default router
