export default {
  // 分类列表
  categoryList: [
    {
      id: '378957',
      name: '半导体',
      icon: 'el-icon-cpu',
      list: [{
        id: '11',
        name: '二极管'
      },
      {
        id: '12',
        name: '晶体管'
      },
      {
        id: '13',
        name: '整流器'
      },
      {
        id: '14',
        name: '放大器'
      },
      {
        id: '15',
        name: '集成电路'
      },]
    },
    {
      id: '68734',
      name: '光耦/发光管/红外',
      icon: 'el-icon-cpu',
      list:[{
        id:'21',
        name:'光耦'
      },
      {
        id:'22',
        name:'发光管'
      },{
        id:'23',
        name:'红外'
      }]
    },
    {
      id: '34789',
      name: '被动器件',
      icon: 'el-icon-cpu',
      list:[{
        id:'31',
        name:'电容',
      },
      {
        id:'32',
        name:'电阻',
      },
      {
        id:'33',
        name:'电感',
      },
      {
        id:'34',
        name:'晶振',
      },
      {
        id:'35',
        name:'继电器',
      },
      {
        id:'36',
        name:'保险丝',
      }]
    },
    {
      id: '93843',
      name: '连接器',
      icon: 'el-icon-cpu',
      list:[{
        id:'41',
        name:'FFC/FPC连接器'
      },
      {
        id:'42',
        name:'线到线连接器'
      },
      {
        id:'43',
        name:'电源连接器'
      },
      {
        id:'44',
        name:'D-sub'
      },
      {
        id:'45',
        name:'板到板连接器'
      },
      {
        id:'46',
        name:'线到板连接器'
      }]
    },
    {
      id: '934843',
      name: '开发工具',
      icon: 'el-icon-cpu',
      list:[{
        id:'51',
        name:'树莓派'
      },{
        id:'52',
        name:'开发板'
      },{
        id:'53',
        name:'外设模块'
      },{
        id:'54',
        name:'烧录器'
      },{
        id:'55',
        name:'焊接工具'
      },
    ]
    },
    {
      id: '87906',
      name: '仪器仪表', 
      icon: 'el-icon-cpu',
      list:[{
        id:'61',
        name:'电子测量仪器'
      },{
        id:'62',
        name:'光学检测仪器'
      },{
        id:'63',
        name:'气体检测仪器'
      },{
        id:'64',
        name:'力学检测仪器'
      },{
        id:'65',
        name:'环境测量仪器'
      }]
    }
  ]
}