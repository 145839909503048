import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// 挂载数据
import category from '@/common/category.js'
Vue.prototype.$category = category
import news from '@/common/news.js'
Vue.prototype.$news = news
import goods from '@/common/goods.js'
Vue.prototype.$goods = goods
import technical from '@/common/technical'
Vue.prototype.$technical = technical
import order from '@/common/order'
Vue.prototype.$order = order
import user from '@/common/user'
Vue.prototype.$user = user
import golobal from '@/common/golobal'
Vue.prototype.$golobal = golobal

Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
