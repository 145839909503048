<template>
  <div class="content">
    <el-container style="height: 100vh">
      <el-header height="155px">
        <div class="top">
          <!-- logo -->
          <div class="logo">
            <img src="../assets/logo.png" alt="" />
            <span>电子云配件网</span>
          </div>
          <!-- 搜索框 -->
          <div class="search">
            <input
              type="text"
              placeholder="请输入商品名称/型号/类别"
              v-model="searchKey"
            />
            <div class="search-btn" @click="openSearch()">搜索</div>
          </div>
          <div class="user" v-if="isLogin">
            <img :src="$golobal.avatar" alt="" />
            <span class="nickname">{{ $golobal.nickname }}</span>
            <el-button @click="quit()" style="margin-top: 10px;margin-left: 10px;">退出登录</el-button>
          </div>
        </div>
        <div class="menu">
          <el-menu
            router
            :default-active="$route.path"
            class="el-menu-demo"
            mode="horizontal"
            background-color="#2A5CAD"
            text-color="#fff"
            active-text-color="#fff"
          >
            <el-menu-item index="/home/index"> 首页 </el-menu-item>
            <el-menu-item index="/home/product">产品库</el-menu-item>
            <el-menu-item index="/home/date">技术资料</el-menu-item>
            <el-menu-item index="/home/news">行业资讯</el-menu-item>
            <el-menu-item index="/home/free">免费样品</el-menu-item>
            <!-- <el-menu-item index="/home/order-list" v-if="isLogin"
              >我的订单</el-menu-item
            > -->
            <el-menu-item index="/home/my-center" 
            v-if="isLogin" >个人中心</el-menu-item
            >
          </el-menu>
        </div>
      </el-header>
      <el-container>
        <el-main>
          <!-- 二级路由占位符 -->
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchKey: "", //搜索关键字
      isLogin:false
      
    };
  },
  mounted(){
    this.isLogin=this.$golobal.isLogin
  },
  methods: {
    quit() {
      this.$golobal.isLogin = false;
      this.isLogin=false
      console.log(this.$golobal.isLogin)
    },
    openSearch() {
      this.$router.push({
        name: "/home/goods-list",
        params: { key: this.searchKey },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  min-width: 960px;
}
.el-header {
  padding: 0 !important;
  background-color: #fff;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .top {
    width: 100%;
    height: 105px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .logo {
      margin-right: 80px;
      img {
        width: 45px;
        height: 45px;
        margin-right: 10px;
      }
      span {
        font-size: 24px;
        font-weight: bold;
        color: #f28f00;
      }
    }
    .search {
      display: flex;
      flex-direction: row;
      align-items: center;
      border: 3px solid #f28f00;
      border-radius: 6px;

      input {
        border: none;
        outline: none;
        padding: 10px 20px;
        font-size: 18px;
        width: 400px;
      }
      .search-btn {
        background-color: #f28f00;
        color: #fff;
        padding: 10px 15px;
      }
    }
    .user {
      display: flex;
      align-items: center;
      position: absolute;
      top:0;
      bottom:0;
      right:40px;
      img {
        width: 70px;
        height: 70px;
        border-radius: 70px;
      }
      .nickname{
        margin-left: 10px;
        font-weight: bold;
        margin-top: 10px;
        margin-right: 20px;
      }
    
    }
  }
  .menu {
    width: 100%;
    background-color: #2a5cad;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: row;
    line-height: 65px;
  }

  i {
    // font-size: 1.3em;
    font-size: 20px;
  }
}
.el-menu-demo {
  height: 65px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.menu {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 30px;
}
// 去除边框等内部样式修改
::v-deep .el-menu.el-menu--horizontal {
  border-bottom: none;
}
::v-deep .el-menu--horizontal > .el-menu-item {
  height: 65px;
  font-size: 1em;
  display: flex;
  justify-content: center;
  line-height: 65px;
  font-weight: 500;
  margin-left: 35px;
  margin-right: 35px;
}

::v-deep .el-menu--horizontal > .el-menu-item.is-active {
  background-color: #f28f00 !important;
}
::v-deep .el-menu--horizontal > .el-menu-item:hover {
  background-color: #f28f00 !important;
}
.el-main {
  background-color: #fff;
  color: #333;
  text-align: center;
  padding: 0;
}
</style>
