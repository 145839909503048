export default {
  isLogin:false,
	nickname: 'Rose',
	avatar: 'https://img2.baidu.com/it/u=2002379065,1964502694&fm=253&fmt=auto&app=120&f=JPEG?w=510&h=500',
	age:23,
	sex:'女',
	phone:'13312345678',
	note:'平安喜乐',
	password:''

}